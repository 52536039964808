export const enum WebEventTypes {
    VIEW_EVENT = "web_onboarding_screen_viewed",
    RESULT_EVENT = "web_onboarding_screen_result",
    WEB_BUTTON_CLICK = "web_button_click",
    WEB_LINK_CLICK = "web_link_click",
    PURCHASE_STARTED = "web_purchase_started",
    PURCHASE_COMPLETED = "web_purchase_completed",
    PURCHASE_FAILED = "web_purchase_failed",
    PURCHASE_UPGRADE = "web_purchase_upgrade",
    ERROR_EVENT = "web_error",
    AB_TEST = "web_ab_test",
    PAYWALL_SCROLL = "web_onboarding_screen_scroll",
    PAYWALL_SHOWN = "web_paywall_shown",
    FORCE_LINK = "web_force_link",
    AGE_WARNING = "age_warning_message",
}

export const enum WebEventProps {
    BUTTON_NAME = "button_name",
    SCREEN = "screen",
    RESULT = "result",
    TYPE = "type",
    PRICE = "price",
    METHOD = "method",
    SUB_DURATION = "subDuration",
    INTRO_OFFER = "intro_offer",
    ERROR_TEXT = "error_text",
    PERCENTAGE = "percentage",
    PRODUCT_ID = "product_id",
}

export type EventPropsType = { [key in WebEventProps]?: string | number | null | boolean };

export const enum EventScreenTypes {
    PRIMARY_GOAL = "primary_goal",
    BIGGEST_DRIVE = "biggest_drive",
    EMAIL = "email",
    EMAIL_CONSENT = "email_consent",
    SHARE_VIDEO = "share_video",
    FIRST_LOADER = "first_loader",
    CIRCULAR_PROMO = "promo_circular",
    PROMO_PAYWALL = "promo_paywall",
    CIRCULAR_LOADER = "circular_loader",
    WINBACK_POPUP = "winback_popup",
    FIRST_SCREEN_AGE_GROUP = "first_screen_age_group",
    AGE_ADAPT_PROMO_SCREEN = "adapt_promo_screen",
    SOCIAL_PROOF = "social_proof",
    AI_WEIGHT_PREDICT = "ai_weight_prediction",
    AI_WEIGHT_FIRST_PREDICT = "ai_weight_first_prediction",
    NAME = "name",
    AI_LAB = "ai_lab",
    UPSELL = "upsell",
}

export const enum EventInputTypes {
    EMAIL = "email",
}

export const enum EventButtonTypes {
    EMAIL = "email",
    SUBMIT_EMAIL = "submit_email",
    BACK = "back",
    OPEN_PAYMENT_POPUP = "open_payment_popup",
    SWIPE_AI_SLIDER = "swipe_ai_slider",
    PAYMENT_CONSENT = "payment_consent",
    OPEN_PROFILE = "open_profile",
}

export const enum EventResultTypes {
    ACCEPT = "accept",
    DECLINE = "decline",
}

export const enum EventLinkTypes {
    privacyPolicy = "privacy_policy",
    refundPolicy = "refund_policy",
    termsOfUse = "terms_of_use",
    supportLink = "support_link",
    faq = "faq",
}
